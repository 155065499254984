import React from 'react';
import { Link } from 'gatsby';
import LogoSymbol from '../assets/neu-logo-symbol-black.svg';
import styled from 'styled-components';

const Logo = () => (
  <Link to={'/'}>
    <SiteLogo src={LogoSymbol} />
  </Link>
);

const SiteLogo = styled.img`
  height: 2.5rem;
`;
export default Logo;
