import { loadStripe } from '@stripe/stripe-js';

const STRIPE_PUBLIC_KEY =
  'pk_live_51IGurmFnZzj19N8Ood4zrcyJrELGCoFbEw8oYDtEt0ztU7ufWYWpd1kaYry8nXdIMTGICyazpZbuFxFVU3kGMkhC00ld8DhywZ';

const ENDPOINT_LOCAL = 'http://localhost:3001';
export const ENDPOINT_PROD =
  'https://dyycdn1lv7.execute-api.us-west-1.amazonaws.com/Prod';
export const FUNCTIONS_BASE_URL =
  process.env.NODE_ENV === 'development' ? ENDPOINT_LOCAL : ENDPOINT_PROD;

export async function submitPartner(data) {
  return await fetch(`${FUNCTIONS_BASE_URL}/partner`, {
    method: 'POST',
    mode: 'cors',
    headers: {
      'content-type': 'application/json',
    },
    body: JSON.stringify(data),
  });
}
export async function submitCity(data) {
  return await fetch(`${FUNCTIONS_BASE_URL}/city`, {
    method: 'POST',
    mode: 'cors',
    headers: {
      'content-type': 'application/json',
    },
    body: JSON.stringify(data),
  });
}
export async function submitSaveBuild(data) {
  return await fetch(`${FUNCTIONS_BASE_URL}/build`, {
    method: 'POST',
    mode: 'cors',
    headers: {
      'content-type': 'application/json',
    },
    body: JSON.stringify(data),
  });
}
export async function submitRenter(data) {
  return await fetch(`${FUNCTIONS_BASE_URL}/rent`, {
    method: 'POST',
    mode: 'cors',
    headers: {
      'content-type': 'application/json',
    },
    body: JSON.stringify(data),
  });
}
export async function submitOwner(data) {
  // console.log('SUBMIT STRIPE', data);
  const stripePromise = loadStripe(STRIPE_PUBLIC_KEY);
  const stripe = await stripePromise;
  // Call backend to create the Checkout Session
  const response = await createCheckout(data);
  const session = await response.json();

  return await stripe.redirectToCheckout({
    sessionId: session.id,
  });

  // if (result.error) {
  // If `redirectToCheckout` fails due to a browser or network
  // error, display the localized error message to your customer
  // using `result.error.message`.
  // }
}
export async function submitSponsor(data) {
  // console.log('SUBMIT STRIPE', data);
  const stripePromise = loadStripe(STRIPE_PUBLIC_KEY);
  const stripe = await stripePromise;
  // Call backend to create the Checkout Session
  const response = await createSponsor(data);
  const session = await response.json();

  return await stripe.redirectToCheckout({
    sessionId: session.id,
  });

  // if (result.error) {
  // If `redirectToCheckout` fails due to a browser or network
  // error, display the localized error message to your customer
  // using `result.error.message`.
  // }
}
export async function getBuild(id) {
  const response = await fetch(`${FUNCTIONS_BASE_URL}/build/${id}`, {
    method: 'GET',
    mode: 'cors',
  });
  return await response.json();
}
export async function createCheckout(data) {
  console.log('create checkout', JSON.stringify(data));
  return await fetch(`${FUNCTIONS_BASE_URL}/checkout`, {
    method: 'POST',
    mode: 'cors',
    headers: {
      'content-type': 'application/json',
    },
    body: JSON.stringify(data),
  });
}
export async function createSponsor(data) {
  console.log('create checkout', JSON.stringify(data));
  return await fetch(`${FUNCTIONS_BASE_URL}/sponsor`, {
    method: 'POST',
    mode: 'cors',
    headers: {
      'content-type': 'application/json',
    },
    body: JSON.stringify(data),
  });
}
