import { createPortal } from 'react-dom';
import React, { useEffect, useRef } from 'react';
import styled from 'styled-components';

const Modal = ({ handleClose, anchorStyle, children }) => {
  // modal mechanic set up
  const portalRoot =
    typeof document !== `undefined` ? document.getElementById('portal') : null;

  // scroll lock behind modal
  useEffect(() => {
    typeof document !== `undefined` &&
      document.body.classList.add('scroll-lock');
    return () =>
      typeof document !== `undefined` &&
      document.body.classList.remove('scroll-lock');
  }, []);

  // listen for ESC key to close modal by focusing on the background element
  const handleKeyDown = e => {
    e.stopPropagation(); // prevent event bubbling when modal opens on top of modals
    e.keyCode === 27 && handleClose();
  };
  const overlayEl = useRef(null);
  useEffect(() => overlayEl.current.focus(), []);

  // on resize, close modal if it's positioned
  useEffect(() => {
    anchorStyle?.left &&
      typeof document !== `undefined` &&
      window.addEventListener('resize', handleClose);
    return () => {
      anchorStyle?.left &&
        typeof document !== `undefined` &&
        window.removeEventListener('resize', handleClose);
    };
  }, []);

  const content = (
    <Overlay
      tabIndex={-1}
      ref={overlayEl}
      onKeyDown={handleKeyDown}
      onClick={handleClose}
    >
      <Container style={anchorStyle || {}}>{children}</Container>
    </Overlay>
  );
  return createPortal(content, portalRoot);
};

// use invisible Overlay to capture click outside event
const Overlay = styled.section`
  //width: 100vw;
  //height: 100vh;
  position: fixed;
  overflow: auto;
  top: 0;
  right: 0;
  bottom: 0;
  left: 0;
  background-color: transparent;
  z-index: 6; // site header at 5, modal overlay at 6, modal header at 10, decor at -1
`;
const Container = styled.div`
  position: absolute;
  height: auto;
`;

export default Modal;
