import { createGlobalStyle } from 'styled-components';
import { colors, devices, FontFaces, fontFamily } from './theme';

export const GlobalStyles = createGlobalStyle`
  ${FontFaces}
    // Normalize
  html {
    box-sizing: border-box;
    width: 100%;
    font-size: var(--font-size);
    scroll-behavior: smooth;
  }

  .scroll-lock {
    overflow: hidden;
  }

  *,
  *:before,
  *:after {
    box-sizing: inherit;
    font-family: inherit;
  }

  body {
    margin: 0;
    width: 100%;
    min-height: 100%;
    //overflow-x: hidden;
    -moz-osx-font-smoothing: grayscale;
    -webkit-font-smoothing: antialiased;
    font-family: ${fontFamily};
    background-color: ${colors.white};
    color: ${colors.black};
  }

  h1,
  h2,
  h3,
  h4,
  h5,
  h6 {
    margin: 0;
    white-space: pre-wrap;
  }

  p {
    margin: 0;
    padding: 0;
    white-space: pre-wrap;
  }

  a {
    text-decoration: none;
    text-decoration-skip-ink: auto;
    color: inherit;
    cursor: pointer;

    // &:focus {
      //   outline-color: ${colors.warmGreen};
    // }
  }

  button {
    cursor: pointer;
    border: none;
    background-color: transparent;
    font-size: 1rem;
    margin: 0;
    padding: 0;

    &:focus,
    &:active {
      outline: none;
        //outline-color: ${colors.dark};
    }
  }

  section:focus {
    outline: none;
  }

  ul {
    margin: 0;
    padding: 0;
    list-style: none;
  }

  hr {
    margin: 0;
  }

  pre {
    padding: 0;
    margin: 0;
    font-family: ${fontFamily};
  }

  ::selection {
    color: ${colors.dark};
    background-color: ${colors.gray};
  }

  textarea {
    font-family: ${fontFamily};
  }

  // input:focus, textarea:focus{
  //   outline: none;
  //   border: 1px solid ${colors.warmGreen};
  //   box-shadow: 0 0 5px ${colors.warmGreen};
  // }

  // responsive variables
  :root {
    --font-size: 16px;
    @media (${devices.m}) {
      --font-size: 14px;
    }

    --nav-bar-height: 6.5rem;
    --nav-bar-padding-y: 2rem;
    --nav-bar-padding-x: 2rem;
    @media (${devices.s}) {
      --nav-bar-height: 5rem;
      --nav-bar-padding-x: 1rem;
      --nav-bar-padding-y: 1rem;
    }

    --banner-height: 6.75rem;
    @media (${devices.m}) {
    --banner-height: 6.7rem;
    }
    @media (${devices.s}) {
      --banner-height: 5.7rem;
    }
    @media (${devices.xs}) {
      --banner-height: 5rem;
    }
    @media (max-width: 384px) {
      --banner-height: 7rem;
    }

    --section-padding-y: 3.25rem;
    --body-padding-x: 9.5rem;
    --body-padding-y: 7.75rem;
    --body-column-gap: 5.5rem;
    @media (${devices.m}) {
      --body-padding-x: 4.5rem;
      --body-column-gap: 3rem;
    }
    @media (${devices.s}) {
      --body-padding-x: var(--nav-bar-padding-x);
      --body-padding-y: 1.7rem;
    }

    --text-block-padding-right: 3%;
    --text-block-padding-y: 3.25rem;
    @media (${devices.s}) {
      --text-block-padding-right: 20%;
      --text-block-padding-y: 2rem;
    }
    @media (${devices.xs}) {
      --text-block-padding-right: 0;
    }

    --image-text-row-gap: 6.375rem;
    --heading-paragraph-row-gap: 2rem;
    @media (${devices.s}) {
      --image-text-row-gap: 1.65rem;
      --heading-paragraph-row-gap: 1.8rem;
    }

    --gallery-grid-gap: 2rem;
    @media (${devices.s}) {
      --gallery-grid-gap: 1rem;
    }

    --footer-padding-top: 4rem;
    --footer-padding-bottom: 27.75rem;
    @media (${devices.s}) {
      --footer-padding-top: 2rem;
      --footer-padding-bottom: 3rem;
    }

    --builder-module-options-padding: 1.7rem 2rem 1.7rem 3rem;
    --builder-grid-options-padding: 1.7rem 4.5rem 3.125rem 5rem;
    --builder-grid-options-padding-x: 0 4.5rem 0 5rem;
    --builder-grid-banner-padding: 1.5rem 4.5rem 3.125rem 5rem;
    --builder-module-banner-padding: 1.5rem 2rem 1.5rem 3rem;
    --builder-grid-banner-padding-bottom: 3.125rem;
    @media (${devices.m}) {
      --builder-module-options-padding: 1.2rem 3vw 1.2rem 3vw;
      --builder-grid-options-padding: 1.2rem 3rem 2rem 3rem;
      --builder-grid-options-padding-x: 0 4vw ;
      --builder-grid-banner-padding: 1rem 3rem 2rem 3rem;
      --builder-module-banner-padding: 1rem 3vw 1.25rem 3vw;
      --builder-grid-banner-padding-bottom: 2rem;
    }
    @media (${devices.s}) {
      --builder-module-options-padding: 1rem .75rem;
      --builder-grid-options-padding: 1rem 1.5rem;
      --builder-grid-options-padding-x: 0 1.5rem;
      --builder-grid-banner-padding: 1rem 1.5rem;
      --builder-module-banner-padding: 1rem .75rem;
      --builder-grid-banner-padding-bottom: 1rem;
    }

    --builder-nav-padding: 2rem;
    @media (${devices.m}) {
      --builder-nav-padding: 1.5rem;
    }
    @media (${devices.s}) {
      --builder-nav-padding: 1rem;
    }

    --option-padding: 1rem 1.5rem;
    --option-padding-r: 1.5rem;
    @media (${devices.m}) {
      --option-padding: .8rem 1.2rem;
      --option-padding-r: 1.2rem;
    }
    @media (${devices.s}) {
      --option-padding: .8rem ;
      --option-padding-r: .8rem;
    }

    --radio-height: 6rem;
    --radio-gap: .7rem;
    --radio-check-circle: 2rem;
    @media (${devices.m}) {
      --radio-height: 5rem;
      --radio-gap: .5rem;
      --radio-check-circle: 1.75rem;
    }
    @media (${devices.s}) {
      --radio-height: auto;
      --radio-check-circle: 1.5rem;
    }

    --checkbox-size: 2rem;
    @media (${devices.m}) {
      --checkbox-size: 1.5rem;
    }

    --tooltip-padding-y: 0.875rem;
    --tooltip-padding-x: 1.5rem;

    --button-width: 10rem;
    --button-height: 3.5rem;
    @media (${devices.s}) {
      --button-height: 2.5rem;
    }

    --color-option-container-size: 4rem;
    --texture-option-container-size: 4.5rem;
    --color-option-size: 3rem;
    @media (${devices.m}) {
      --texture-option-container-size: 3.5rem;
      --color-option-container-size: 3rem;
      --color-option-size: 2rem;
    }


    --hr-margin: 1rem 0 .75rem;
    @media (${devices.m}) {
      --hr-margin: .5rem 0 .4rem;
    }
    @media (${devices.s}) {
      --hr-margin: .25rem 0 ;
    }

    --modal-header-padding: 2rem;
    @media (${devices.m}) {
      --modal-header-padding: 1.5rem;
    }

    --list-dropdown-height: 4rem;
    --list-dropdown-padding: 0 1.5rem;
    --list-dropdown-col-gap: 1.5rem;

    --modal-prompt-padding: 2.5rem 1.5rem;
    --modal-prompt-padding-x: 1.5rem;
    @media (${devices.s}) {
      --modal-prompt-padding: 0.75rem 0.5rem;
      --modal-prompt-padding-x: 0.5rem;
    }
  }

`;
