import React, { useEffect, useRef, useState } from 'react';
import styled from 'styled-components';
import { GatsbyImage } from 'gatsby-plugin-image';
import { AiFillLeftCircle } from '@react-icons/all-files/ai/AiFillLeftCircle';
import { colors, devices } from '../styles/theme';
import { RiFullscreenFill } from '@react-icons/all-files/ri/RiFullscreenFill';

const ImageCarousel = ({
  images,
  className,
  children,
  reportIndex,
  initialIndex = 0,
  objectFit = 'cover',
  objectPosition = '50% 50%',
  fullScreenIcon,
  hideNav,
  autoNext,
}) => {
  const scroller = useRef(null);
  const maxIndex = images.length - 1;

  const [currentImageIndex, setImageIndex] = useState(initialIndex);
  const handleScrollTo = index => {
    index < 0 && (index = maxIndex);
    index > maxIndex && (index = 0);
    const singleImageWidth = scroller.current.getBoundingClientRect().width;
    scroller.current.scrollLeft = singleImageWidth * index;
  };

  // re-calculate current image index on scroll
  const handleScrollEvent = e => {
    // e.persist(); // react version 16 only, not needed in 17
    window.requestAnimationFrame(() => {
      const singleImageWidth = scroller.current?.getBoundingClientRect().width;
      const currentScrollPosition = e.target.scrollLeft;
      const index = Math.round(currentScrollPosition / singleImageWidth);
      index !== currentImageIndex && scroller.current && setImageIndex(index);
    });
  };

  reportIndex && reportIndex(currentImageIndex);

  useEffect(() => {
    initialIndex !== 0 && handleScrollTo(initialIndex);
  }, []);

  const timeoutRef = useRef(null);
  useEffect(() => {
    autoNext &&
      (timeoutRef.current = setTimeout(
        () => handleScrollTo(currentImageIndex + 1),
        autoNext
      ));
    return () => timeoutRef.current && clearTimeout(timeoutRef.current);
  }, [currentImageIndex]);

  return (
    <Container className={className}>
      <Scroller ref={scroller} onScroll={handleScrollEvent}>
        {images.map((item, index) =>
          item.image ? (
            <ImageContainer key={item.image.name} id={'image' + index}>
              <GatsbyImage
                image={item.image.childImageSharp.gatsbyImageData}
                alt={item.image.name}
                objectFit={objectFit}
                objectPosition={objectPosition}
              />
            </ImageContainer>
          ) : (
            <ImageContainer key={item.area} id={'image' + index}>
              <GatsbyImage
                image={item.images[0].childImageSharp.gatsbyImageData}
                alt={item.images[0].name}
                objectFit={objectFit}
                objectPosition={item.objectPosition || objectPosition}
                style={{ gridArea: '1 / 1', zIndex: 0 }}
              />
              {item.images[1] && (
                <GatsbyImage
                  image={item.images[1].childImageSharp.gatsbyImageData}
                  alt={item.images[1].name}
                  objectFit={objectFit}
                  objectPosition={item.objectPosition || objectPosition}
                  style={{ gridArea: '1 / 1', zIndex: 1 }}
                />
              )}
            </ImageContainer>
          )
        )}
        {images.length > 1 && !hideNav && (
          <CarouselNavContainer>
            <LeftArrowCircle
              color={colors.blue}
              onClick={() => handleScrollTo(currentImageIndex - 1)}
            />
            <CarouselNavItemContainer>
              {images.map((_, index) => (
                <CarouselNavItem
                  key={index}
                  onClick={() => handleScrollTo(index)}
                  aria-current={index === currentImageIndex}
                />
              ))}
            </CarouselNavItemContainer>
            <RightArrowCircle
              color={colors.blue}
              onClick={() => handleScrollTo(currentImageIndex + 1)}
            />
          </CarouselNavContainer>
        )}
        {fullScreenIcon && (
          <FullScreenIcon
            size='32px'
            color={colors.gray}
            id='full-screen-icon'
          />
        )}
      </Scroller>
      {children && children(currentImageIndex)}
    </Container>
  );
};

const Container = styled.div`
  height: 100%;
  position: relative;
`;
const Scroller = styled.div`
  height: 100%;
  max-height: 100vh;
  display: flex;
  scroll-snap-type: x mandatory;
  overflow-x: auto;
  scroll-behavior: smooth;
  -webkit-overflow-scrolling: touch;
  // hide horizontal scroll bar
  -ms-overflow-style: none; // Internet Explorer
  scrollbar-width: none; // Firefox
  &::-webkit-scrollbar {
    // Chrome and Safari
    display: none;
  }
`;
const ImageContainer = styled.div`
  flex: 0 0 100%;
  scroll-snap-align: start;
  display: grid;
  //max-height: 100vh;
`;
const CarouselNavContainer = styled.div`
  position: absolute;
  bottom: 2.5rem;
  left: 50%;
  transform: translateX(-50%);
  z-index: 2;
  background-color: ${colors.white};
  display: grid;
  grid-template-columns: 1fr auto 1fr;
  align-items: center;
  grid-gap: 1rem;
  height: 3.5rem;
  padding: 0.3rem;
  border-radius: 1.75rem;
  @media (${devices.xs}) {
    height: 2.5rem;
    border-radius: 1.25rem;
  }
`;
const LeftArrowCircle = styled(AiFillLeftCircle)`
  cursor: pointer;
  width: 2.85rem;
  height: 2.85rem;
  @media (${devices.xs}) {
    width: 1.9rem;
    height: 1.9rem;
  }
`;
const RightArrowCircle = styled(LeftArrowCircle)`
  transform: rotateZ(180deg);
`;
const CarouselNavItemContainer = styled.ul`
  display: grid;
  grid-auto-flow: column;
  align-items: center;
  grid-gap: 0.5rem;
`;
const CarouselNavItem = styled.li`
  width: 0.75rem;
  height: 0.75rem;
  border-radius: 50%;
  background-color: ${colors.gray};
  cursor: pointer;

  &[aria-current='true'] {
    background-color: ${colors.blue};
  }
`;
const FullScreenIcon = styled(RiFullscreenFill)`
  position: absolute;
  right: 1.25rem;
  bottom: 3.11rem;
  cursor: pointer;
  z-index: 2;
  filter: drop-shadow(0px 0px 3px #000000);
  :hover {
    transform: scale(1.1);
    filter: brightness(200%) drop-shadow(0px 0px 6px #000000);
  }
  @media (${devices.xs}) {
    bottom: 2.57rem;
  }
`;
export default ImageCarousel;
