import React, { useEffect, useState } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { saveFormSubmission } from '../store/app';
import {
  getBuildState,
  getTotalDownPayment,
  getTotalFullPrice,
  getTotalPrice,
} from '../store/build';
import { injectExtraFormData } from './helpers';

const useForm = (
  formName,
  initialValues,
  validator,
  isSubmitting,
  setSubmitting,
  submit
) => {
  const dispatch = useDispatch();
  const [values, setValues] = useState(initialValues);
  const [errors, setErrors] = useState({});

  const handleChange = ({ target: { name, value } }) => {
    setValues(prevValues => ({ ...prevValues, [name]: value }));
  };

  const handleBlur = ({ target: { name, value } }) => {
    const { valuesAreValid, errors } = validator({ [name]: value });
    setErrors(prevErrors => {
      if (valuesAreValid) {
        // remove current field's error
        const { [name]: noop, ...newErrors } = prevErrors;
        return newErrors;
      } else {
        return { ...prevErrors, ...errors };
      }
    });
  };

  const handleSubmit = e => {
    e.preventDefault();
    const { valuesAreValid, errors } = validator(values);
    setErrors(errors);
    valuesAreValid && setSubmitting(true);
  };
  // extra info for submission
  const buildState = useSelector(getBuildState);
  const totalMonthly = useSelector(getTotalPrice);
  const totalFullPrice = useSelector(getTotalFullPrice);
  const totalDownPayment = useSelector(getTotalDownPayment);
  useEffect(() => {
    if (isSubmitting) {
      const injectedData = injectExtraFormData(formName, values, {
        buildState,
        totalMonthly,
        totalDownPayment,
        totalFullPrice,
      });
      submit(injectedData).then(response => {
        if (response.status === 200) {
          // update app state to remember form submission and user info
          // const { firstName, lastName, phone, email } = values;
          console.log('submit success', response);
          response.json().then(result => {
            const newValues = { ...values, ...result };
            dispatch(saveFormSubmission(formName, newValues));
            setSubmitting(false);
          });
        } else {
          setErrors({ submission: 'Submission failed, please try again...' });
          setSubmitting(false);
          // remove submission error
          setTimeout(
            () =>
              setErrors(prev => {
                const { submission: noop, ...newErrors } = prev;
                return newErrors;
              }),
            4000
          );
        }
      });
    }
  }, [isSubmitting]);
  return { values, handleChange, handleBlur, handleSubmit, errors };
};
export default useForm;
