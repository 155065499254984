import React from 'react';
import styled from 'styled-components';
import Logo from './Logo';
import closeIcon from '../assets/icons/close.svg';
import { colors } from '../styles/theme';

const ModalHeader = ({ handleClose }) => {
  return (
    <Container>
      <Logo />
      <CloseButton src={closeIcon} alt='close button' onClick={handleClose} />
    </Container>
  );
};

const Container = styled.header`
  padding: var(--modal-header-padding);
  display: flex;
  justify-content: space-between;
  grid-column: 1 / -1;
  align-items: center;
  position: sticky;
  top: 0;
  background-color: ${colors.white}B3;
  backdrop-filter: blur(0.3rem);
  z-index: 10;
`;
const CloseButton = styled.img`
  cursor: pointer;
`;

export default ModalHeader;
