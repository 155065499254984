import styled, { keyframes } from 'styled-components';
import { colors, devices, fontWeights } from './theme';
import { Link } from 'gatsby';
import { IoMdCloseCircle } from '@react-icons/all-files/io/IoMdCloseCircle';
import ImageCarousel from '../components/ImageCarousel';
import { AiFillPlayCircle } from '@react-icons/all-files/ai/AiFillPlayCircle';

// animations
export const fadeIn = keyframes`
  0% {
    opacity: 0;
    transform: scale(.9);
  }
  100% {
    opacity: 1;
    transform: scale(1);
  }
`;
export const bounce = keyframes`
  0% {
    transform: translateY(0);
  }
  25% {
    transform: translateY(-10px);
  }
  50% {
    transform: translateY(0);
  }
  75% {
    transform: translateY(-5px);
  }
  100% {
    transform: translateY(0);
  }
`;
export const slideUp = keyframes`
  0% {
    opacity: 0;
    transform: translateY(70vh);
  }
  100% {
    opacity: 1;
    transform: translateY(0);
  }
`;
export const grow = keyframes`
  0% {
    max-height:0;
    opacity: 0;
  }
  10%{
    opacity: 0;
  }
  70% {
    max-height: 1000px;
  }
  100%{
    opacity: 1;
  }
`;

// components
export const ButtonLink = styled(Link)`
  width: 12rem;
  height: 3.5rem;
  border: 1px solid ${colors.black};
  border-radius: 0.375rem;
  display: flex;
  justify-content: center;
  align-items: center;
  // center text in button
  > p {
    display: flex;
    align-items: flex-end;
    --height: 2.5rem;
    height: var(--height);

    > span {
      line-height: calc(var(--height) * 0.9);
    }
  }
  :hover {
    color: ${colors.blue};
    border-color: ${colors.blue};
  }
  @media (${devices.s}) {
    width: 9.4rem;
    height: 3rem;
  }
  @media (${devices.xs}) {
    width: 100%;
  }
`;
export const Button = styled.button`
  width: 12rem;
  height: 3.5rem;
  border: 1px solid ${colors.black};
  border-radius: 0.375rem;
  :hover {
    color: ${colors.blue};
    border-color: ${colors.blue};
  }
`;
export const BuildLink = styled(Link)`
  font-size: 1rem;
  line-height: 2.125rem;
  width: 10rem;
  height: 3.5rem;
  border: 1px solid ${colors.gray};
  border-radius: 0.375rem;
  display: flex;
  justify-content: center;
  align-items: center;

  :hover {
    background-color: ${colors.blue};
    color: ${colors.white};
    border-color: transparent;
  }
  transition: all 0.15s ease-in;

  @media (${devices.xs}) {
    width: 100%;
    background-color: ${colors.blue};
    color: ${colors.white};
    border: none;
  }
`;
export const TextNavLink = styled(Link)`
  display: flex;
  justify-content: center;
  align-items: center;
  text-align: center;
  padding: 0.75rem;
  --bottom-border-size: 0.25rem;
  border-bottom: var(--bottom-border-size) solid ${colors.gray};

  &:hover {
    border-bottom: var(--bottom-border-size) solid ${colors.dark};
  }

  &.active {
    border-bottom: var(--bottom-border-size) solid ${colors.blue};
  }

  @media (${devices.s}) {
    padding: 0.5rem;
    --bottom-border-size: 2px;
  }
  @media (${devices.xs}) {
    border-bottom: none;
    &:hover {
      border-bottom: none;
      color: ${colors.blue};
    }

    &.active {
      border-bottom: none;
      border-left: 0.25rem solid ${colors.blue};
    }
  }
`;
export const TextNavButton = styled.button`
  --bottom-border-size: 0.25rem;
  border-bottom: var(--bottom-border-size) solid ${colors.gray};

  &:disabled {
    border-bottom: var(--bottom-border-size) solid ${colors.lightGray};
    cursor: unset;
  }

  &:hover:enabled {
    border-bottom: var(--bottom-border-size) solid ${colors.dark};
  }

  &[aria-current='true'] {
    border-bottom: var(--bottom-border-size) solid ${colors.blue};
  }
`;

export const SolidButton = styled.button`
  width: 12rem;
  height: 3.5rem;
  border-radius: 0.375rem;
  display: flex;
  justify-content: center;
  align-items: center;
  background-color: ${colors.blue};
  color: ${colors.white};
  :hover {
    opacity: 0.85;
  }
  &:disabled {
    filter: opacity(0.3);
    cursor: auto;
  }
`;
export const LinkStyleButton = styled.button`
  font-size: 0.875rem;
  line-height: 2rem;
  color: ${colors.blue};
  text-decoration: underline;
  @media (${devices.s}) {
    line-height: 1.25;
  }
`;
export const TextStyleButton = styled(LinkStyleButton)`
  text-align: start;
  text-decoration: none;
  color: ${colors.black};
`;
export const PaddedGrid = styled.div`
  padding: 0 var(--body-padding-x);
  display: grid;
  grid-template-columns: 1fr 1fr;
  column-gap: var(--body-column-gap);
  position: relative;
`;

export const ImageCaptionOverlayTitle = styled.h2`
  letter-spacing: 0;
  line-height: 2rem;

  font-size: 1.25rem;
  font-weight: ${fontWeights.bold};
`;

export const ImageCaptionOverlaySubtitle = styled.h5`
  letter-spacing: 0;
  line-height: 2rem;

  font-size: 0.875rem;
  font-weight: ${fontWeights.regular};
  margin-top: -0.3rem;
`;

export const ImageCaptionOverlayDescription = styled.p`
  letter-spacing: 0;
  line-height: 2rem;
  font-size: 1rem;
`;

export const OptionsTitle = styled.h1`
  font-size: 2.125rem;
  font-weight: ${fontWeights.bold};
  line-height: 3rem;
  @media (${devices.s}) {
    font-size: 1.5rem;
    line-height: 2rem;
  }
`;
export const OptionsDescription = styled.p`
  font-size: 1rem;
  font-weight: ${fontWeights.regular};
  line-height: 2rem;
  @media (${devices.sv}) {
    line-height: 1.5rem;
  }
`;
export const OptionsNote = styled.p`
  font-size: 0.875rem;
  font-weight: ${fontWeights.regular};
  font-style: italic;
  line-height: 2rem;
`;
export const OptionTitle = styled.h2`
  font-size: 1.25rem;
  font-weight: ${fontWeights.medium};
  text-align: left;
  @media (${devices.s}) {
    font-size: 1rem;
  }
`;
export const OptionSubtitle = styled.h2`
  font-size: 1rem;
  font-weight: ${fontWeights.regular};
  text-align: left;
  ${props => props.highlight && `color: ${colors.red};`}
`;
export const OptionInfoTitle = styled.h2`
  font-size: 1.25rem;
  font-weight: ${fontWeights.medium};
  line-height: 4rem;
  @media (${devices.s}) {
    line-height: 2rem;
    margin-bottom: 1rem;
  }
`;
export const OptionInfoDescription = styled.p`
  font-size: 1rem;
  font-weight: ${fontWeights.regular};
  line-height: 2rem;
  @media (${devices.s}) {
    line-height: 1.8rem;
  }
`;
export const BuilderOptionsContainer = styled.section`
  padding: var(--builder-grid-options-padding);
  display: grid;
  grid-gap: 1.25rem;
  @media (${devices.s}), (${devices.sv}) {
    grid-gap: 0.75rem;
    padding-top: 0;
    padding-bottom: 0;
  }
  @media (${devices.xs}) {
    padding-top: 1.5rem;
  }
`;
export const BuilderMediaContainer = styled.aside`
  height: 100%;
`;
export const Hr = styled.hr`
  width: 100%;
  border-top: 1px solid ${colors.black};
`;
export const FullScreenModalContainer = styled.section`
  background-color: ${colors.white};
  min-height: 100%;
  animation: ${slideUp} 0.4s ease-out;
`;
export const ModalContentContainer = styled.main`
  display: grid;
  row-gap: 4rem;
  padding-bottom: 4rem;
  grid-template-columns: 1fr 35.5rem 1fr;
  align-content: start;

  & > * {
    grid-column: 2 / 3;
  }

  @media (${devices.s}) {
    grid-template-columns: 1fr;
    padding: 1.5rem 13%;
  }
  @media (${devices.xs}) {
    padding: 1.5rem 1rem;
  }
`;
export const ModalTextBlock = styled.div`
  display: grid;
  grid-gap: 1rem;

  h1 {
    font-weight: ${fontWeights.bold};
    font-size: 2.125rem;
    line-height: 3rem;
    @media (${devices.xs}) {
      font-size: 1.7rem;
    }
  }
  h2 {
    font-weight: ${fontWeights.bold};
    font-size: 1.375rem;
    line-height: 2.125rem;
    @media (${devices.xs}) {
      font-size: 1rem;
      line-height: 1.5rem;
    }
  }

  p {
    font-size: 1rem;
    line-height: 2rem;
  }
`;
export const TransparentBackgroundModalContainer = styled.div`
  height: 100%;
  width: 100%;
  //max-width: 3000px;
  display: grid;
  justify-content: center;
  align-content: center;
  background-color: ${colors.darkTransparent};
`;
export const Form = styled.form`
  display: grid;
  grid-template-columns: 1fr 1fr;
  grid-template-rows: repeat(2, 6rem);
  column-gap: 2rem;

  p {
    font-size: 1rem;
    line-height: 1.5rem;

    &.error {
      color: ${colors.red};
      font-size: 0.875rem;
      line-height: 1.3125rem;
      justify-self: end;
    }
  }

  @media (${devices.xs}) {
    grid-template-columns: 1fr;
  }
`;
export const InputGroup = styled.div`
  display: grid;
  grid-template-rows: 1.5rem 3rem 1.3125rem;
`;
export const Input = styled.input`
  border: 1px solid ${colors.gray};
  border-radius: 0.375rem;

  &.error {
    border-color: ${colors.red};
  }
`;

export const ThankYou = styled.div`
  grid-column: 1 / -1;
  grid-row: 1 / -1;
  display: grid;
  row-gap: 1rem;
  justify-content: center;
  align-content: center;
  background-color: ${colors.light};
  border-radius: 0.375rem;
  font-size: 1rem;
  line-height: 1.5rem;
  padding: 0 2rem;
  h2 {
    font-size: 1.25rem;
    font-weight: ${fontWeights.medium};
  }
  a {
    color: ${colors.blue};
    text-decoration: underline;
  }
`;
export const FormAgreementContainer = styled.div`
  width: 100%;
  grid-column: 1 / -1;
  display: grid;
  row-gap: 1rem;
  margin-bottom: 1.5rem;
  button {
    color: ${colors.blue};
  }
  p.agreement-text {
    animation: ${grow} 0.8s linear;
  }
`;
export const FormActionButton = styled(SolidButton)`
  width: 100%;
  align-self: end;
  grid-column: 1 / -1;

  &:disabled {
    filter: opacity(0.3);
    cursor: auto;
  }
`;
export const FormDueToday = styled.div`
  //grid-row: 1 / span 1;
  //grid-column: span 1 / -1;
  align-self: start;
  justify-self: end;
  background-color: ${colors.light};
  display: grid;
  align-items: center;
  justify-items: center;
  padding: 1.5rem 2.5rem;
  border-radius: 0.375rem;

  h2 {
    font-weight: ${fontWeights.medium};
    font-size: 1rem;
    line-height: 1.5rem;
    text-decoration: underline;
  }

  h1 {
    font-weight: ${fontWeights.bold};
    font-size: 2.875rem;
    line-height: 2.875rem;
    padding-top: 1.25rem;
  }
`;
export const FormFootNote = styled.p`
  font-size: 0.875rem;
  line-height: 1.5rem;

  a {
    color: ${colors.blue};
  }
`;
export const FormPricing = styled.div`
  grid-column: 1 / -1;
  padding: 1.5rem 0;
  border-bottom: 1px solid ${colors.gray};
  margin-bottom: 1.5rem;
  display: grid;
  grid-template-columns: 1fr 1fr;
  column-gap: 2rem;
  font-size: 1rem;
  line-height: 1.5rem;

  .note {
    margin-bottom: 1.5rem;
  }

  .total {
    font-weight: ${fontWeights.bold};
  }
`;

export const RadioButton = styled.button`
  width: calc(1.5rem + 2px);
  height: calc(1.5rem + 2px);
  border-radius: 50%;
  border: 1px solid ${colors.gray};
  position: relative;
  cursor: inherit;

  :after {
    content: '';
    border-radius: 50%;
    width: 0.75rem;
    height: 0.75rem;
    background-color: transparent;
    position: absolute;
    top: 50%;
    left: 50%;
    transform: translateX(-50%) translateY(-50%);
  }
`;

export const TextBlock = styled.div`
  grid-column: span 1;
  grid-row: span 1;
  display: grid;
  row-gap: var(--heading-paragraph-row-gap);

  > h1 {
    font-weight: ${fontWeights.bold};
    font-size: 4rem;
    line-height: 4.5rem;
  }

  > p {
    font-size: 1rem;
    line-height: 2rem;
  }

  padding-right: var(--text-block-padding-right);
  @media (${devices.s}) {
    grid-column: 1 / -1;
    > h1 {
      font-size: 3.4rem;
      line-height: 5.1rem;
    }
  }
  @media (${devices.xs}) {
    > h1 {
      font-size: 2.6rem;
      line-height: 3.5rem;
    }
  }
`;

export const FeatureItem = styled.div`
  display: grid;
  grid-template-columns: 3rem 1fr;
  column-gap: 1.5rem;
  @media (${devices.xs}) {
    grid-column: 1 / -1;
  }
`;

export const FeatureText = styled.div`
  display: grid;
  row-gap: 0.5rem;

  h3 {
    font-weight: ${fontWeights.bold};
    font-size: 1.25rem;
    line-height: 2.125rem;
  }

  p {
    font-weight: ${fontWeights.regular};
    font-size: 1rem;
    line-height: 2rem;
  }
`;

export const StartingOptions = styled.div`
  display: grid;
  grid-template-columns: repeat(3, 1fr);
  column-gap: 2rem;
  row-gap: 3rem;
  @media (${devices.xs}) {
    grid-template-columns: 1fr;
  }
`;
export const StartingOption = styled.div`
  display: grid;
  row-gap: 1.625rem;
  grid-template-rows: auto 1fr;
`;
export const OptionInfo = styled.div`
  --row-gap: 0.875rem;
  display: grid;
  row-gap: var(--row-gap);
  align-self: stretch;
  align-content: space-between;
  .content {
    display: grid;
    row-gap: var(--row-gap);
  }
  .actions {
    display: grid;
    row-gap: var(--row-gap);
  }
  h2 {
    font-weight: ${fontWeights.bold};
    font-size: 1.5rem;
    line-height: 2rem;
    @media (${devices.xs}) {
      //text-align: center;
    }
  }

  p {
    font-size: 1rem;
    line-height: 1.5rem;
    margin-bottom: 0.25rem;
  }

  li {
    line-height: 2rem;
  }
`;
export const Intent = styled.div`
  display: grid;
  grid-template-columns: auto auto;
  column-gap: 0.5625rem;
  justify-content: start;

  h3 {
    font-weight: ${fontWeights.medium};
    font-size: 1rem;
    line-height: 2rem;
  }
  @media (${devices.s}) {
    grid-template-columns: auto;
  }
`;
export const LearnMoreLink = styled(Link)`
  justify-self: start;
  grid-column: 1 / -1;
  grid-row: 2;
  font-size: 0.875rem;
  line-height: 1.25rem;
  color: ${colors.blue};
  text-decoration: underline;
  @media (${devices.s}) {
    grid-row: unset;
    justify-self: start;
    margin-top: 0.5rem;
  }
`;
export const CloseButton = styled(IoMdCloseCircle)`
  cursor: pointer;
  grid-area: 1 / 1;
  position: sticky;
  top: 0.5rem;
  right: 1rem;
  justify-self: end;
  align-self: start;
  margin: 0.5rem 1rem;
  z-index: 2;
  filter: drop-shadow(0px 0px 2px ${colors.darkTransparent});
`;
export const MediaCarouselContainer = styled.aside`
  height: 100%;

  & img {
    cursor: zoom-in;
  }
`;
export const ModalCarouselWrapper = styled.div`
  height: 100%;
  background-color: ${colors.darkTransparent};
  display: grid;
  align-content: center;
`;
export const ModalCarousel = styled(ImageCarousel)`
  grid-area: 1 / 1;
  animation: 0.3s ${fadeIn} ease-out;
`;
export const ModalCloseButton = styled(IoMdCloseCircle)`
  cursor: pointer;
  grid-area: 1 / 1;
  position: sticky;
  top: 0.5rem;
  right: 1rem;
  justify-self: end;
  align-self: start;
  margin: 0.5rem 1rem;
  z-index: 1;
`;
export const VideoContainer = styled.div`
  width: 100%;
  padding-bottom: 56.25%; // 16:9 aspect ratio
  position: relative;
`;
export const Video = styled.video`
  position: absolute;
`;
export const VideoPoster = styled.div`
  display: grid;

  & > * {
    grid-row: 1 / -1;
    grid-column: 1 / -1;
  }
`;
export const PlayButton = styled(AiFillPlayCircle)`
  align-self: center;
  justify-self: center;
  z-index: 2;
  cursor: pointer;
  :hover {
    color: ${colors.blue};
  }
`;
export const PlayButtonBackground = styled.div`
  z-index: 1;
  width: 2.5rem;
  height: 2.5rem;
  background-color: ${colors.white};
  border-radius: 50%;
  align-self: center;
  justify-self: center;
`;
export const ComparisonLeft = styled.div`
  display: grid;
  grid-template-rows: 7.125rem repeat(5, 3rem) 11.5rem;
  justify-items: center;
  align-items: center;
  align-content: start;
  background-color: ${colors.blue};
  color: ${colors.white};
  padding: 0 8%;
  > h2 {
    font-weight: ${fontWeights.bold};
    font-size: 1.5rem;
    @media (${devices.s}) {
      font-size: 1.2rem;
    }
  }
`;
export const ComparisonRight = styled(ComparisonLeft)`
  background-color: ${colors.lightGray};
  color: ${colors.black};
`;
export const ComparisonItem = styled.div`
  justify-self: start;
  display: flex;
  align-items: center;
  p {
    font-size: 1rem;
    margin-left: 0.625rem;
  }
  & #check-mark-icon {
    fill: ${colors.white};
  }
`;

export const ComparisonActionLink = styled(Link)`
  width: var(--button-width);
  height: var(--button-height);
  border: 1px solid ${colors.gray};
  color: ${colors.black};
  background-color: ${colors.white};
  border-radius: 0.375rem;
  display: flex;
  justify-content: center;
  align-items: center;
  :hover {
    color: ${colors.blue};
    &.right {
      border-color: ${colors.blue};
    }
  }
`;
