import AzoSansRegular from '../assets/fonts/AzoSansRegular.woff';
import AzoSansRegular2 from '../assets/fonts/AzoSansRegular.woff2';
import AzoSansRegularItalic from '../assets/fonts/AzoSansItalic.woff';
import AzoSansRegularItalic2 from '../assets/fonts/AzoSansItalic.woff2';

import AzoSansBold from '../assets/fonts/AzoSansBold.woff';
import AzoSansBold2 from '../assets/fonts/AzoSansBold.woff2';

import AzoSansMedium from '../assets/fonts/AzoSansMedium.woff';
import AzoSansMedium2 from '../assets/fonts/AzoSansMedium.woff2';

import { css } from 'styled-components';

export const fontFamily = 'Azo Sans, sans-serif';
export const fontWeights = {
  regular: 400,
  medium: 500,
  bold: 700,
};
export const FontFaces = css`
  @font-face {
    font-family: 'Azo Sans';
    font-style: normal;
    font-weight: ${fontWeights.regular};
    src: url(${AzoSansRegular2}) format('woff2'),
      url(${AzoSansRegular}) format('woff');
  }

  @font-face {
    font-family: 'Azo Sans';
    font-style: italic;
    font-weight: ${fontWeights.regular};
    src: url(${AzoSansRegularItalic2}) format('woff2'),
      url(${AzoSansRegularItalic}) format('woff');
  }

  @font-face {
    font-family: 'Azo Sans';
    font-style: normal;
    font-weight: ${fontWeights.medium};
    src: url(${AzoSansMedium2}) format('woff2'),
      url(${AzoSansMedium}) format('woff');
  }

  @font-face {
    font-family: 'Azo Sans';
    font-style: normal;
    font-weight: ${fontWeights.bold};
    src: url(${AzoSansBold2}) format('woff2'),
      url(${AzoSansBold}) format('woff');
  }
`;

export const colors = {
  white: '#FFFFFF',
  black: '#000000',
  blue: '#0045ff',
  gray: '#DBDEE7',
  f8: '#F8F8F8',
  darkGray: '#BDBDBD',
  lightGray: '#F5F8FD',
  light: '#F6F6F6',
  lighter: '#F2F2F2',
  dark: '#212121',
  red: '#FF4646',
  darkTransparent: 'rgba(33,33,33,0.8)',
  offWhiteTransparent: 'rgba(230,230,230,0.3)',
  lightTransparent: 'rgba(180,180,180,0.3)',

  warmGreen: '#c2cba5',
  teal: '#50E3C2',
  hr: '#D8D8D8',
  arrow: '#010101',
  info: '#757575',
  error: 'orangered',
};
export const devices = {
  xs: 'max-width: 600px',
  s: 'max-width: 1024px',
  sv: 'max-height: 860px',
  m: 'max-width: 1440px',
  l: 'max-width: 1600px',
  xl: 'min-width: 1601px',
};

//
// export const transitions = {
//   medium: 'transition: all 0.3s',
//   long: 'transition: all 0.8s',
// };
//
// console debug use
export const logStyles = {
  red: 'background-color: red; color: white; padding:4px;',
  green: 'background-color: green; color: white; padding:4px;',
  blue: 'background-color: blue; color: white; padding:4px;',
  black: 'background-color: black; color: white; padding:4px;',
};
