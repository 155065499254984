import React, { useState } from 'react';
import styled from 'styled-components';
import { colors, devices } from '../../styles/theme';
import {
  Form,
  FormActionButton,
  Input,
  InputGroup,
  ThankYou,
} from '../../styles/shared';
import { useSelector } from 'react-redux';
import useForm from '../../utils/useForm';
import { getSubmittedForms, getVisitorInfo } from '../../store/app';
import { validateForm } from '../../utils/helpers';
import { submitPartner } from '../../utils/api';
import { partnerModalContent } from '../../utils/dataConfig';

const formName = 'partner';

const PartnerForm = () => {
  const [isSubmitting, setSubmitting] = useState(false);
  const visitorInfo = useSelector(getVisitorInfo);
  const formSubmitted = useSelector(getSubmittedForms).some(
    f => f === formName
  );

  const { values, handleChange, handleBlur, handleSubmit, errors } = useForm(
    formName,
    visitorInfo,
    validateForm,
    isSubmitting,
    submitting => setSubmitting(submitting),
    submitPartner
  );

  return (
    <FormContainer onSubmit={handleSubmit}>
      {formSubmitted ? (
        <ThankYou>{partnerModalContent.thankYouMessage}</ThankYou>
      ) : (
        <>
          <InputGroup>
            <p>First name</p>
            <Input
              type='text'
              name='firstName'
              onChange={handleChange}
              value={values.firstName}
              onBlur={handleBlur}
              required
              className={!!errors.firstName ? 'error' : ''}
            />
            <p className='error'>{errors.firstName}</p>
          </InputGroup>
          <InputGroup>
            <p>Last name</p>
            <Input
              type='text'
              name='lastName'
              onChange={handleChange}
              value={values.lastName}
              onBlur={handleBlur}
              required
              className={!!errors.lastName ? 'error' : ''}
            />
            <p className='error'>{errors.lastName}</p>
          </InputGroup>
          <InputGroup>
            <p>Email address</p>
            <Input
              type='email'
              name='email'
              onChange={handleChange}
              value={values.email}
              onBlur={handleBlur}
              required
              className={!!errors.email ? 'error' : ''}
            />
            <p className='error'>{errors.email}</p>
          </InputGroup>
          <InputGroup>
            <p>Phone number</p>
            <Input
              type='tel'
              name='phone'
              onChange={handleChange}
              value={values.phone}
              onBlur={handleBlur}
              className={!!errors.phone ? 'error' : ''}
            />
            <p className='error'>{errors.phone}</p>
          </InputGroup>
          <FullWidthInputGroup>
            <p>Comments</p>
            <Textarea
              type='text'
              name='comments'
              value={values.comments}
              onChange={handleChange}
            />
          </FullWidthInputGroup>
          <FormActionButton
            type='submit'
            disabled={isSubmitting || Object.keys(errors).length !== 0}
          >
            {isSubmitting ? 'Submitting...' : 'Submit'}
          </FormActionButton>
          {errors.submission && (
            <p className='error' style={{ gridColumn: '1 / -1' }}>
              {errors.submission}
            </p>
          )}
        </>
      )}
    </FormContainer>
  );
};
const FormContainer = styled(Form)`
  grid-template-rows: repeat(2, 6rem) 10rem 6rem;
  @media (${devices.xs}) {
    grid-template-rows: repeat(4, 6rem) 10rem 6rem;
  }
`;
const FullWidthInputGroup = styled(InputGroup)`
  grid-column: 1 / -1;
  grid-template-rows: auto 1fr;
`;

const Textarea = styled.textarea`
  border: 1px solid ${colors.gray};
  border-radius: 0.375rem;
  resize: none;
`;

export default PartnerForm;
