import React from 'react';
import ModalHeader from '../ModalHeader';
import {
  FullScreenModalContainer,
  ModalContentContainer,
  ModalTextBlock,
} from '../../styles/shared';
import { partnerModalContent } from '../../utils/dataConfig';
import PartnerForm from '../forms/PartnerForm';

const Partner = ({ handleClose }) => {
  return (
    <FullScreenModalContainer onClick={e => e.stopPropagation()}>
      <ModalHeader handleClose={handleClose} />
      <ModalContentContainer>
        <ModalTextBlock>
          <h1>Partner with Neu</h1>
          <h2>Build-to-Rent Partners:</h2>
          <p>
            Neu’s Housing co-op is exclusive for owner occupants. However,
            separate from the co-op, Neu offers a rental option for those who
            are not yet ready or qualified to buy but want a stepping stone into
            becoming a Neu Owner. Our Neu Renter program partners with
            build-to-rent investors to provide affordable rental options.
            Participating in this program our investment partners agree that Neu
            will not only manage the properties and rental program but that a
            percentage of each months rent will be held back for the renter to
            eventually use to help supplement their down payment to become a Neu
            Owner of another Neu Home.
          </p>
          <h2>Commercial Partners:</h2>
          <p>
            Each Neu Community includes commercial activations including Retail,
            Restaurants and Entertainment. Neu offers the opportunity to both
            local and national brands to join our Neu Community and offer your
            experience to our residents and guests.
          </p>
          <h2>Real-estate Partners:</h2>
          <p>
            Neu partners with landowners and developers to bring Neu Communities
            to your area. Do you have a city or location that would love to have
            a Neu Community. Reach out to learn more about how we can partner to
            bring a Neu Community to you.
          </p>
          <h2>Technology Partners:</h2>
          <p>
            Neu Communities are a physical platform that the latest in
            technology and experiences plugs into. New partners with companies
            to pilot and test their latest technology in our Neu Communities.
          </p>
          <h2>Neu Start-up Campus:</h2>
          <p>
            It’s time to redefine what it means to live-work-play. Each Neu
            Community offers a campus environment for small to medium size
            companies to have a Neu office and provide housing for their
            employees. Contact us today to learn how Neu can become your
            companies campus.
          </p>
        </ModalTextBlock>
        <PartnerForm />
      </ModalContentContainer>
    </FullScreenModalContainer>
  );
};

export default Partner;
