import React from 'react';
import { Helmet } from 'react-helmet';
import { graphql, useStaticQuery } from 'gatsby';
import logo from '../assets/neu-logo-symbol-black.svg';

const SEO = ({ title, description, image, location, children }) => {
  const {
    site: { siteMetadata },
  } = useStaticQuery(graphql`
    query {
      site {
        siteMetadata {
          title
          description
        }
      }
    }
  `);
  return (
    <Helmet titleTemplate={`%s - ${siteMetadata.title}`}>
      <html lang='en' />
      <title>{title}</title>
      <link rel='icon' type='image/svg+xml' href={logo} />
      <link
        rel='alternate icon'
        type='image/png'
        sizes='32x32'
        href='/favicon-32x32.png'
      />
      <link
        rel='alternate icon'
        type='image/png'
        sizes='16x16'
        href='/favicon-16x16.png'
      />
      <link
        rel='apple-touch-icon'
        sizes='180x180'
        href='/apple-touch-icon.png'
      />
      <link rel='manifest' href='/site.webmanifest' />
      <link rel='mask-icon' href='/safari-pinned-tab.svg' color='#5bbad5' />
      <meta name='msapplication-TileColor' content='#da532c' />
      <meta name='theme-color' content='#ffffff' />
      <meta name='viewport' content='width=device-width,initial-scale=1.0' />
      <meta charSet='utf-8' />
      <meta name='description' content={siteMetadata.description} />

      {location && <meta property='og:url' content={location.href} />}
      <meta property='og:image' content={image || logo} />
      <meta property='og:title' content={title} key='ogtitle' />
      <meta
        property='og:site_name'
        content={siteMetadata.title}
        key='ogsitename'
      />
      <meta property='og:description' content={description} key='ogdesc' />
      {children}
    </Helmet>
  );
};

export default SEO;
